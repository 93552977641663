import React from "react";
import {
  Box,
  ListItem,
  Heading,
  Text,
  UnorderedList,
  Center,
  Image,
  Flex,
} from "@chakra-ui/react";
import "../style/Depistage.css";
import Header from "../components/Nav";
import Footer from "../components/Footer";
import banniere from "../images/gaia.png";

function Gestion() {
  return (
    <>
      <Header />
      <Box className="content" backgroundColor={"#02011E"}>
        <Box className="depistageBackground" width={"100%"} height={"auto"}>
          <Image src={banniere} width={"100%"} height={"auto"} alt="" />
        </Box>

        <Box className="solutionContent" id="home">
          <Box className="title">
            <hr />
            <Heading as={"h2"} size={"lg"} mb={"15px"}>
              Logiciel de gestion de laboratoire d’analyse microbiologie{" "}
            </Heading>
          </Box>

          <Text>
            GAIA est une solution LIMS (Système de gestions des informations de
            laboratoire) personnalisée pour les besoins du laboratoire en
            micro-biologie ADGène. Cet outils permettant la gestion des clients,
            la réalisation des devis et des commandes, la reception des
            échantillons, l’enregistrement des résultat et la transmission des
            résultats soit par mail soit sur une plateforme sécurisée.
          </Text>
          <Flex justifyContent={"space-between"} mt={"100px"}>
            <Box className="fonctionalite">
              <Heading as={"h3"} size={"md"} mb={"15px"}>
                Les fonctionnalités :
              </Heading>
              <UnorderedList>
                <ListItem>Gestion administratives de clients</ListItem>
                <ListItem>Gestion des devis et des bons de commande</ListItem>
                <ListItem>
                  Réception des échantillons et enregistrements des résultats
                </ListItem>
                <ListItem>Edition des rapports d’essai</ListItem>
                <ListItem>Gestion des statistiques</ListItem>
                <ListItem>
                  Mise en place d’une plateforme client permettant la
                  récupération des rapports d’essai
                </ListItem>
              </UnorderedList>
            </Box>
          </Flex>
        </Box>
      </Box>
      <>
        <Footer />
      </>
    </>
  );
}
export default Gestion;
